import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss']
})
export class PointOfSaleComponent implements OnInit {
  @Input() communityDetails
  @Input() terminalId
  displayMobilePOS: boolean = true
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectWidthChanges(window.innerWidth)
  }
  constructor(
    public modalRef: MdbModalRef<PointOfSaleComponent>,
    ) { }

  ngOnInit() {
    console.log(this.terminalId)
    this.detectWidthChanges(window.innerWidth)
  }

  detectWidthChanges(screenWidth: number) {
    this.displayMobilePOS = (screenWidth <= 900)
  }

}
