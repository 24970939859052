<ng-container *ngIf="loggedIn; else showNavigatorLogin">
  <ng-container [ngSwitch]="currentView">
    <div *ngSwitchCase="'home'" [@fadeInEnter]>
      <ng-container *ngIf="eventDetails; else noEventDetails">
        <div class="container mt-4">
          <div class="row">
            <div class="col">
              <div style="width: 100%">
                <h2 class="mb-0 pb-0">Hi {{userProfile.given_name}}!</h2>
                <div class="mb-0 pb-0 mt-0 pt-0">You're currently assigned to the following:

                  <div class="mb-0 pb-0 mt-2 pt-0 text-small">
                    <span>Event:</span><span class="ms-1 text-muted">{{eventDetails.name}}</span>
                  </div>
                  <div class="mb-0 pb-0 mt-0 pt-0 text-small">
                    <span>Doors Open:</span><span class="ms-1 text-muted">{{eventDetails.doorsOpenTime | date:'EEEE'}}
                      at {{eventDetails.doorsOpenTime | date:'shortTime'}}</span>
                  </div>
                  <div class="mb-0 pb-0 mt-0 pt-0 text-small">
                    <span>Start Time:</span><span class="ms-1 text-muted">{{eventDetails.startTime | date:'EEEE'}} at
                      {{eventDetails.startTime | date:'shortTime'}}</span>
                  </div>
                  <div class="mb-0 pb-0 mt-0 pt-0 text-small">
                    <span>Venue:</span><span class="ms-1 text-muted">{{eventDetails.venueDetails.name}}</span>
                  </div>

                </div>
                <div class="mt-1 ms-0 ps-0">
                  <span (click)="setView('change-assignment')" class="me-2 text-primary">Change Assignment</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-4">
          <div class="row text-center">
            <div class="col" *ngFor="let app of apps" (click)="click(app.name)">
              <div mdbRipple class="btn rounded bg-gradient p-4" [ngClass]="app.color" style="width: 100%">
                <i class="{{app.icon}}" style="font-size: 24px; color: white;"></i>
              </div>
              <div class="mb-3">
                {{app.name}}

              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noEventDetails>
        <div class="container mt-4">
          <div class="row">
            <div class="col">
              <div style="width: 100%">
                <h2 class="mb-0 pb-0">Hi {{userProfile.given_name}}!</h2>
                No Assignment Found!
                <div class="mt-1 ms-0 ps-0">
                  <span (click)="setView('change-assignment')" class="me-2 text-primary">Set Assignment</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>



    </div>
    <div *ngSwitchCase="'change-assignment'" [@fadeInEnter]>
      <div class="d-flex pt-2 bg-dark text-light">
        <div *ngIf="eventDetails" (click)="setView('home')" class="ms-1 p-2 flex-shrink-1">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div class="p-2 w-100">
          <h5>
            <ng-container *ngIf="eventDetails; else noEventDetailsLabel">
              Change Assignment
            </ng-container>
            <ng-template #noEventDetailsLabel>
              Set Assignment
            </ng-template>
          </h5>
        </div>
      </div>
      <app-set-assignment [communityDetails]="communityDetails" [joinedCommunities]="joinedCommunities"
        [joinedCommunitiesLoading]="joinedCommunitiesLoading"
        (setAssignment)="setAssignment($event)"></app-set-assignment>
    </div>
    <div *ngSwitchCase="'validate'" [@fadeInEnter]>
      <div class="d-flex pt-2 bg-dark text-light">
        <div (click)="setView('home')" class="ms-1 p-2 flex-shrink-1">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div class="p-2 w-100">
          <h5>Validate Tickets</h5>
        </div>
      </div>
      <app-ticket-scanner></app-ticket-scanner>
    </div>
    <div *ngSwitchCase="'ticket-list'" [@fadeInEnter]>
      <div class="d-flex pt-2 bg-dark text-light">
        <div (click)="setView('home')" class="ms-1 p-2 flex-shrink-1">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div class="p-2 w-100">
          <h5>Ticket List</h5>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'event-stats'" [@fadeInEnter]>
      <div class="d-flex pt-2 bg-dark text-light">
        <div (click)="setView('home')" class="ms-1 p-2 flex-shrink-1">
          <i class="fas fa-arrow-left"></i>
        </div>
        <div class="p-2 w-100">
          <h5>Event Stats</h5>
        </div>
      </div>

      COMPONENT GOES HERE
    </div>
  </ng-container>
</ng-container>
<ng-template #showNavigatorLogin>
  <ng-container *ngIf="!loading && setupDetails.setupCode; else isLoading">
    <div class="mb-3">
      <div class="text-center mt-4 mb-1">
        <div class="text-small">Navigate to</div>
        <div class="fw-bold">https://eventodyssey.com/link-device</div>
      </div>
    </div>
    <div class="text-white mb-3" style="background-color: #007BFF;">
      <div class="text-center mt-1 mb-1">
        <div class="text-small">Enter code</div>
        <div class="display-4 fw-bold">{{ setupDetails.setupCode }}</div>
      </div>
    </div>
    <div class="text-center text-small mt-3 mb-0 pb-0">
      <em>- or scan -</em>
    </div>
    <div class="text-center mb-0 pb-0">
      <img class="img-fluid" [src]="setupDetails.qrCodeUrl" alt="QR Code" style="max-width: 75%;" />
    </div>
  </ng-container>

  <ng-template #isLoading>
    <app-loading></app-loading>
  </ng-template>
</ng-template>