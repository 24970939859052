<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">

        <a class="navbar-brand me-2 mt-0 pt-0 mb-0 pb-0 d-flex align-items-center" style="padding-top: 9px">
            <app-logo [height]="20" style="margin-top: 2px;"></app-logo>
        </a>

    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body text-start">
    <div class="d-grid gap-2 mb-2">
        <button (click)="logout()" class="btn btn-light btn-large text-start" type="button" mdbRipple>
            <div class="d-flex align-items-center">
                <i class="fas fa-lg fa-sign-out-alt me-2"></i>
                <div>
                    Logout
                </div>
            </div>
        </button>
    </div>
    <!-- <div class="d-grid gap-2 mb-2">
        <button class="btn btn-light btn-large text-start" type="button" mdbRipple>
            <div class="d-flex align-items-center">
                <i class="fas fa-lg fa-theater-masks me-2"></i>
                <div>
                    Title<br>
                    <span class="small">Description</span>
                </div>
            </div>
        </button>
    </div>
    <div class="d-grid gap-2 mb-2">
        <button class="btn btn-light btn-large text-start" type="button" mdbRipple>
            <div class="d-flex align-items-center">
                <i class="fas fa-lg fa-theater-masks me-2"></i>
                <div>
                    Title<br>
                    <span class="small">Description</span>
                </div>
            </div>
        </button>
    </div>
    <div class="d-grid gap-2 mb-2">
        <button class="btn btn-light btn-large text-start" type="button" mdbRipple>
            <div class="d-flex align-items-center">
                <i class="fas fa-lg fa-theater-masks me-2"></i>
                <div>
                    Title<br>
                    <span class="small">Description</span>
                </div>
            </div>
        </button>
    </div> -->
    <div class="mb-4"></div>
    <div class="text-end">
        <small>{{version}}</small>
    </div>
</div>