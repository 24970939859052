<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h4 class="display-4 mb-3">Link Device</h4>
      </div>
    </div>
  </div>
</div>
<section>
  <ng-container *ngIf="!error; else displayError">
    <ng-container *ngIf="tvLinked; else displayForm">
      <br class="noselect">
      <app-success [content]="'Signed-in'"></app-success>
    </ng-container>
    <ng-template #displayForm>
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
          <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12" *ngIf="givenName">
            <h4 class="mb-3">Hey {{givenName}}, let's connect your Device! 👋</h4>
            <form [formGroup]="linkTVForm">
              <div *ngIf="testMode">
                <div class="input-group mt-2 mb-4">
                  <div class="input-container">
                    <mdb-form-control>
                      <input mdbInput [mdbValidate] type="text" formControlName="login" id="login"
                        class="form-control form-control-lg" [validateSuccess]="false" placeholder="" />
                      <label mdbLabel class="form-label" for="login">Username</label>
                    </mdb-form-control>
                  </div>
                  <span class="input-group-text border-0"><app-validation-icon
                      [inputValid]="linkTVForm.controls.login.invalid"></app-validation-icon>
                    <i class="far fa-question-circle" mdbTooltip="Enter the username provided for testing our TV App." [html]="true" placement="right"></i></span>
                </div>
                <div class="input-group mt-2 mb-4">
                  <div class="input-container">
                    <mdb-form-control>
                      <input mdbInput [mdbValidate] type="text" formControlName="pass" id="pass" type="password"
                        class="form-control form-control-lg" [validateSuccess]="false" placeholder="" />
                      <label mdbLabel class="form-label" for="pass">Password</label>
                    </mdb-form-control>
                  </div>
                  <span class="input-group-text border-0"><app-validation-icon
                      [inputValid]="linkTVForm.controls.pass.invalid"></app-validation-icon>
                    <i class="far fa-question-circle" mdbTooltip="Enter the password provided for testing our TV App." [html]="true" placement="right"></i></span>
                </div>
              </div>
              <div class="input-group mt-2 mb-4">
                <div class="input-container">
                  <mdb-form-control>
                    <input mdbInput [mdbValidate] type="text" formControlName="setupCode" id="setupCode"
                      class="form-control form-control-lg" [validateSuccess]="false" placeholder="" maxLength="6" />
                    <label mdbLabel class="form-label" for="setupCode">Setup Code</label>
                    <div class="form-helper">
                      <div class="form-counter">{{ linkTVForm.controls.setupCode.value.length }} / 6</div>
                    </div>
                  </mdb-form-control>
                </div>
                <span class="input-group-text border-0"><app-validation-icon
                    [inputValid]="linkTVForm.controls.setupCode.invalid"></app-validation-icon>
                  <i class="far fa-question-circle" mdbTooltip="Enter the code provided from our TV App." [html]="true" placement="right"></i></span>
              </div>
              <div class="text-center">
                <button [disabled]="isSaving" type="submit" (click)="submit()"
                  class="btn btn-primary btn-rounded btn-lg text-capitalize" [disabled]="!linkTVForm.valid">
                  <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"
                      aria-hidden="true"></span> Linking Device</span>
                  <span *ngIf="!isSaving">Link Device</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>


  </ng-container>
  <ng-template #displayError>
    <br class="noselect">
    <br class="noselect">
    <app-https-error [error]="error"></app-https-error>
    <br class="noselect">
    <div class="text-center">
      <button (click)="error = null" class="btn btn-primary btn-rounded btn-lg text-capitalize">
        <span>Try again</span>
      </button>
    </div>
  </ng-template>
</section>
