import { Component, OnInit } from '@angular/core';
import { Options } from 'ngx-qrcode-styling';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss'] // Fixed typo: 'styleUrl' to 'styleUrls'
})
export class CustomerViewComponent implements OnInit {
  public config: Options = {
    width: 400,
    height: 400,
    margin: 2,
    dotsOptions: {
      color: "#4d4d4d",
      type: "extra-rounded"
    },
    shape: 'square',
    backgroundOptions: {
      color: "#ffffff00",
    },
    cornersSquareOptions: {
      color: '#000000'
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 0
    }
  };
  constructor(
    private themeToggleService: ThemeToggleService
  ) {}

  ngOnInit() {
    this.themeToggleService.enableWakeLock()
  }
  
}
