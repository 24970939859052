import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from 'src/app/services/backend.service';
import { DateTime, Settings } from 'luxon';

@Component({
  selector: 'app-set-assignment',
  templateUrl: './set-assignment.component.html',
  styleUrl: './set-assignment.component.scss'
})
export class SetAssignmentComponent {
  @Input() communityDetails
  @Output('setAssignment') setAssignment: EventEmitter<any> = new EventEmitter();
  @Input() joinedCommunities
  @Input() joinedCommunitiesLoading: boolean = true
  communityEvents = []
  loading
  limit = 100
  error
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 24 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  joinedCommunitiesForm: FormGroup;
  eventSelectionForm: FormGroup;
  displaySelectDropdown: boolean = false
  constructor(
    private backendService: BackendService,
  ) { }

  ngOnInit(): void {
    this.setupForms()
    if(this.communityDetails) {
      this.getEvents()
    }
  }

  setupForms() {
    this.joinedCommunitiesForm = new FormGroup({
      community: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required]))
    });
    this.eventSelectionForm = new FormGroup({
      event: new FormControl('', Validators.compose([Validators.minLength(3), Validators.required]))
    });
  }

  changeCommunity() {
    this.communityDetails = null
    this.communityEvents = null
  }

  submitCommunitySelection() {
    this.joinedCommunitiesLoading = true
    this.fetchCommunityDetails(this.joinedCommunitiesForm.controls.community.value)
  }

  submitEventSelection() {
    const eventId = this.eventSelectionForm.controls.event.value
    const eventDetails = this.communityEvents.find(event => event.id === eventId);
    this.setAssignment.emit({
      communityDetails: this.communityDetails,
      eventDetails: eventDetails
    })
  }

  fetchCommunityDetails(communityId: string) {
    this.backendService.getCommunityDetails(communityId).subscribe({
      next: (res) => {
        this.joinedCommunitiesLoading = false
        this.communityDetails = res
        this.getEvents()
        return
      },
      error: (err) => {
        this.error = err.message
        console.log(err)
        return
      },
    });
  }

  
  getEvents() {
    this.loading = true
    this.backendService.getCommunityEvents(this.communityDetails.id, {
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    }).subscribe({
      next: async (res) => {
        this.communityEvents = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  calculateEventsListHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this.communityEvents.length] || baseHeight) + 'px';
  }

  calculateCommunityListHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    return Math.max(minimumHeight, heights[this.joinedCommunities.length] || baseHeight) + 'px';
  }

}
