import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-pos-controls',
  templateUrl: './pos-controls.component.html',
  styleUrls: ['./pos-controls.component.scss']
})
export class PosControlsComponent implements OnInit {
  @Input() communityDetails
  @Input() terminalId: string
  purchasing: boolean = false
  cardReaderId: string = 'tmr_FhzTXA2DOmA77c'
  lineItems = [{
    amount: 1500,
    description: '|  Premium Ticket (T115)',
    quantity: 1
  },
  {
    amount: 1500,
    description: '|  Premium Ticket (T116)',
    quantity: 1
  }]
  error
  constructor(
    private functions: AngularFireFunctions,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
  }

  setReaderDisplay() {
    console.log('set reader display')
    this.purchasing = true

    this.backendService.setCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  clearReaderDisplay() {
    console.log('clear reader display')
    this.purchasing = true

    this.backendService.clearCommunityTerminalDisplay(this.communityDetails.id, this.terminalId, {}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  processReaderPayment() {
    console.log('process reader payment')
    this.purchasing = true

    this.backendService.processCommunityTerminalPayment(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  printReceipt() {
    console.log('print receipt')
    this.purchasing = true

    this.backendService.printCommunityTerminalReceipt(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

  printTickets() {
    console.log('print ticket(s)')
    this.purchasing = true

    this.backendService.printCommunityTerminalTickets(this.communityDetails.id, this.terminalId, {lineItems: this.lineItems}).subscribe({
      next: async (res) => {
        console.log(res)
        this.purchasing = false
      },
      error: (err) => {
        console.log(err)
        console.warn(err)
        this.error = err.message
        return
      },
    });

  }

}
