<div class="container mt-4">
    <div class="row">
        <div class="col">
            <ng-container *ngIf="communityDetails; else selectCommunity">

                <div class="mb-2 pb-0 mt-2 pt-0 text-small">
                    <span class="ms-1 text-muted">{{communityDetails.name}} <span (click)="changeCommunity()"
                            class="ms-1 text-primary">change</span></span>
                </div>
                <form [formGroup]="eventSelectionForm">
                    <div class="input-group mt-2">
                        <div #group class="input-container">
                            <mdb-form-control>
                                <ng-select [virtualScroll]="false" mdbInput formControlName="event"
                                    (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false"
                                    [items]="communityEvents" [multiple]="false" placeholder="Event" bindLabel="name"
                                    bindValue="id" appearance="outline" [closeOnSelect]="true" [clearable]="false"
                                    [searchable]="false" notFoundText="No communities found" autocomplete="off"
                                    required>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <div [ngOptionHighlight]="search" class="lh-1 pt-1 pb-1">
                                            <div class="">{{item.name}}</div>
                                            <div class="text-muted small text-wrap">{{item.startTime | date:'fullDate'}}
                                                at {{item.startTime | date:'shortTime'}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </mdb-form-control>
                        </div>
                    </div>
                    <div *ngIf="displaySelectDropdown" [style.height]="calculateEventsListHeight()"></div>
                    <div class="text-end">
                        <button [disabled]="eventSelectionForm.invalid" (click)="submitEventSelection()"
                            class="btn btn-rounded btn-primary">Select</button>
                    </div>
                </form>


            </ng-container>
            <ng-template #selectCommunity>
                <ng-container *ngIf="!joinedCommunitiesLoading; else loadingCommunities">
                    <form [formGroup]="joinedCommunitiesForm">
                        <div class="input-group mt-2">
                            <div #group class="input-container">
                                <mdb-form-control>
                                    <ng-select [virtualScroll]="false" mdbInput formControlName="community"
                                        (open)="displaySelectDropdown=true" (close)="displaySelectDropdown=false"
                                        [items]="joinedCommunities" [multiple]="false" placeholder="Community"
                                        bindLabel="name" bindValue="id" appearance="outline" [closeOnSelect]="true"
                                        [clearable]="false" [searchable]="false" notFoundText="No communities found"
                                        autocomplete="off" required>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            <div [ngOptionHighlight]="search" class="">
                                                <div class="">{{item.name}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </mdb-form-control>
                            </div>
                        </div>
                        <div *ngIf="displaySelectDropdown" [style.height]="calculateCommunityListHeight()"></div>
                        <div class="text-end">
                            <button [disabled]="joinedCommunitiesForm.invalid" (click)="submitCommunitySelection()"
                                class="btn btn-rounded btn-primary">Select</button>
                        </div>
                    </form>
                </ng-container>
                <ng-template #loadingCommunities>
                    <app-loading></app-loading>
                </ng-template>


            </ng-template>
        </div>
    </div>
</div>