import { Component, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription, timer } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastComponent } from '../shared/toast/toast.component';

@Component({
  selector: 'app-ticket-scanner',
  templateUrl: './ticket-scanner.component.html',
  styleUrls: ['./ticket-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketScannerComponent implements OnInit, OnDestroy {
  @ViewChild('scannerElem') scanner!: ZXingScannerComponent;
  scanContent: any;
  @Input() eventDetails = {
    id: '6iriNZixT8ssnyhRoE6x'
  };
  private scanSuccessSubscription: any;
  private navigationSubscription: any;
  public userActionRequired: boolean = false;
  public hasPermission: boolean = false;
  public isScannerStopped: boolean = false;
  public loadingTicketStatus: boolean = false;
  public availableDevices: MediaDeviceInfo[];
  private currentDeviceIndex: number = -1;
  honeywellScanner: boolean = true;
  public progressWidth: number = 0;
  private progressDuration = 10000;
  private progressSubscription: Subscription | null = null;
  private timeoutSubscription: Subscription | null = null;
  public dontDismissed: boolean = false;
  currentScanId: string = ''
  error
  barcodeScannedListener: any;

  toastOptionsError = environment.toastOptions.navigatorerror as MdbNotificationConfig<{}>;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastService: MdbNotificationService,
    private backendService: BackendService) {
    this.navigationSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.ngOnDestroy();
    });
  }

  ngOnInit() {
    //@ts-ignore
    if (window.Android) {
      //@ts-ignore
      this.honeywellScanner = window.Android.isScannerInitialized();
      if (this.honeywellScanner) {
        this.barcodeScannedListener = (event: any) => {
          const scanData = event.detail.data;
          this.validateTicket(scanData);
          this.cdr.detectChanges();
        };
        window.addEventListener('barcodeScanned', this.barcodeScannedListener);
      
      }
    } else {
      this.honeywellScanner = false;
    }
  }

  resetScanner() {
    this.scanContent = null
  }

  ngOnDestroy(): void {
    if (this.honeywellScanner && this.barcodeScannedListener) {
      window.removeEventListener('barcodeScanned', this.barcodeScannedListener);
    }
    this.scanSuccessSubscription?.unsubscribe();
    this.scanner?.reset();
    this.navigationSubscription?.unsubscribe();
    this.clearSubscriptions();
  }

  validateTicket(scanId: string) {
    if (this.userActionRequired) {
      this.errorToast('User action required before continuing')
      this.sendBadReadNotification()
      return
    }
    if (this.loadingTicketStatus) {
      this.errorToast('Wait until previous ticket has been verified')
      this.sendBadReadNotification()
      return
    }
    this.loadingTicketStatus = true
    this.error = null
    console.log('lets scan id:', scanId);
    this.currentScanId = scanId
    this.backendService.validateTicket(this.eventDetails.id, scanId).subscribe({
      next: async (res) => {
        console.log(res);
        this.loadingTicketStatus = false;
        this.scanContent = res;
        this.dontDismissed = false;
        this.clearSubscriptions();
        switch (res.status) {
          case 'valid':
          case 'issued':
          case 'scanned':
            this.sendGoodReadNotification()
            this.startProgress();
            // Set a timeout to clear scanContent after 5 seconds if "Don't Dismiss" wasn't clicked
            this.timeoutSubscription = timer(this.progressDuration).subscribe(() => {
              if (!this.dontDismissed) {
                this.scanContent = null;
                this.progressWidth = 0; // Reset progress when done
                this.cdr.detectChanges();
              }
            });
            break;
          case 'warning':
          case 'error':
          default:
            this.userActionRequired = true
            this.dontDismissed = true;
            this.sendBadReadNotification()
            break;
        }
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.error = err.message
        this.sendBadReadNotification()
        this.cdr.detectChanges();
        console.warn(err);
        return;
      },
    });
  }


  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }


  sendBadReadNotification() {
    //@ts-ignore
    if (window.Android && window.Android.sendBadReadNotification) {
      //@ts-ignore
      window.Android.sendBadReadNotification();
    } else {
      console.error("Android interface not found or sendScannerCommand method not defined.");
    }
  }

  sendGoodReadNotification() {
    //@ts-ignore
    if (window.Android && window.Android.sendGoodReadNotification) {
      //@ts-ignore
      window.Android.sendGoodReadNotification();
    } else {
      console.error("Android interface not found or sendScannerCommand method not defined.");
    }
  }

  invalidateScanner(status: boolean) {
    console.log('lets change scanner status to ', status)
    //@ts-ignore
    if (window.Android && window.Android.invalidateScans) {
      //@ts-ignore
      window.Android.invalidateScans(status);
    } else {
      console.error("Android interface not found or status method not defined.");
    }
  }

  forceValidateTicket() {
    this.loadingTicketStatus = true
    this.backendService.forceValidateTicket(this.eventDetails.id, this.currentScanId).subscribe({
      next: async (res) => {
        this.userActionRequired = false
        this.scanContent = null
        this.loadingTicketStatus = false
        this.cdr.detectChanges()
        console.log(res);
      },
      error: (err) => {
        this.error = err.message
        this.sendBadReadNotification()
        this.cdr.detectChanges();
        console.warn(err);
        return;
      },
    });
  }

  startProgress() {
    console.log('start progress')
    this.progressWidth = 100;
    const interval = 50; // Update every 50ms
    const step = 100 / (this.progressDuration / interval);

    // Start the progress bar animation
    this.progressSubscription = timer(0, interval).subscribe(() => {
      if (this.progressWidth > 0) {
        this.progressWidth -= step;
        this.cdr.detectChanges();
      } else {
        this.clearProgressSubscription();
      }
    });
  }

  dismissActionRequired() {
    this.loadingTicketStatus = false
    this.userActionRequired = false
    this.error = null
    this.scanContent = null
  }

  clearSubscriptions() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }
  }

  clearProgressSubscription() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
      this.progressSubscription = null;
    }
  }

  dontDismiss() {
    this.dontDismissed = true;
    this.clearSubscriptions(); // Stop the progress bar and timeout
    this.progressWidth = 0; // Optionally reset the progress bar to 0
    this.cdr.detectChanges();
  }

  async scanSuccessHandler($event) {
    this.scanContent = String($event);
    this.validateTicket(this.scanContent);
    this.scanner?.scanStop();
    this.isScannerStopped = true;
    this.loadingTicketStatus = true;
    this.cdr.detectChanges();
    await this.delay(3000);
    this.loadingTicketStatus = false;
    this.cdr.detectChanges();
    await this.delay(5000);
    this.isScannerStopped = false;
    this.cdr.detectChanges();
    this.scanner?.scanStart();
  }

  async scanErrorHandler($event) {
    console.log($event);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  camerasFoundHandler(devices: MediaDeviceInfo[]): void {
    this.hasPermission = true;
    this.availableDevices = devices;
    if (devices && devices.length > 0) {
      this.currentDeviceIndex = 0;
      this.scanner.device = this.availableDevices[this.currentDeviceIndex];
    }
  }

  permissionResponseHandler(hasPermission: boolean): void {
    this.hasPermission = hasPermission;
  }

  toggleCamera(): void {
    if (this.availableDevices && this.availableDevices.length > 0) {
      this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.availableDevices.length;
      this.scanner.device = this.availableDevices[this.currentDeviceIndex];
    }
  }

}
