<ng-container *ngIf="chargeDetails; else noDetails">
    <div #pdfLoaded id="pdfLoaded">
      <div class="mt-3 ms-4 me-4 mb-4">
        <div>
          <div class="d-flex">
            <div class="flex-fill">
              <h5>Receipt from {{communityDetails.name}}</h5>
              <div class="small">Order ID: {{chargeDetails.id.replace('ch_', '')}}</div>
              <div class="small">Transaction Date: {{(chargeDetails.created*1000) | date:'medium'}}</div>
            </div>
            <div class="flex-fill">
              <div class="text-end">
                <img src="./assets/logo.png" height="30" alt="" />
                <div>support&#64;eventodyssey.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 ms-4 me-4">
        <div class="">
          <div class="d-flex justify-content-between">
            <div>
              <div class="small mb-2">Customer Details</div>
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="customerDetails.profilePic"
                  (error)="customerDetails.profilePic = './assets/user-profile.png'" alt=""
                  style="width: 45px; height: 45px;" class="rounded-circle" />
                <div class="ms-2">
                  <p class="%T%^mt-0 mb-1" style="line-height: 1;">{{customerDetails.displayName}}</p>
                  <p class="mt-0 mb-0" style="line-height: 1;">{{customerDetails.email}}</p>
                </div>
              </div>
            </div>
            <div class="flex-fill text-end">
              <div class="small mb-1" style="line-height: 1;">Card Details</div>
              <app-card-detail [cardDetails]="chargeDetails.payment_method_details"></app-card-detail>
            </div>
          </div>
        </div>
      </div>
  
  
      <div class="mt-4 mb-5 ms-4 me-4">
        <div class="">
          <div class="row border border-3">
            <div class="col-6">
              <div class="small">Item Details</div>
            </div>
            <div class="col-2">
              <div class="small">Price</div>
            </div>
            <div class="col-2">
              <div class="small">Tax</div>
            </div>
            <div class="col-2">
              <div class="small">Total</div>
            </div>
          </div>
          <div *ngFor="let groupedItem of lineItems" class="row border-start border-end border-bottom border-3">
            <div class="row">
              <h5 class="mt-1" style="margin-bottom: 0px; text-transform: capitalize;">{{groupedItem.eventDetails.name}}
              </h5>
            </div>
            <div class="row rowPadding" *ngFor="let item of groupedItem.lineItems">
              <div class="col-6">
                <h6 style="margin-bottom: 0px; text-transform: capitalize;">{{item.type}}</h6>
                <div class="small">
                  <span>Seat: {{item.row}}/{{item.seat}} ({{item.section}})</span>
                </div>
              </div>
              <div class="col-2 text-start">
                {{(item.price/100) | currency}}
              </div>
              <div class="col-2 text-start">
                <ng-container *ngIf="item.tax>0; else valueZero">
                  {{item.tax | currency}}
                </ng-container>
                <ng-template #valueZero>
                  ---
                </ng-template>
              </div>
              <div class="col-2 text-start">
                {{(item.price/100) | currency}}
              </div>
            </div>
          </div>
          <div class="row border-start border-end border-bottom border-3" style="height: 5px;"></div>
          <div class="row border border-3">
            <div class="row rowPadding">
              <div class="col-8">
              </div>
              <div class="col-2">
                Subtotal
              </div>
              <div class="col-2">
                {{(chargeDetails.amount/100) | currency}}
              </div>
            </div>
            <div class="row rowPadding">
              <div class="col-8">
              </div>
              <div class="col-2">
                Tax
              </div>
              <div class="col-2">
                <ng-container *ngIf="chargeDetails.tax>0; else valueZero">
                  {{chargeDetails.tax | currency}}
                </ng-container>
                <ng-template #valueZero>
                  ---
                </ng-template>
              </div>
            </div>
            <div class="row border-start border-end border-bottom border-3 rowPadding" style="height: 2px;"></div>
            <div class="row rowPadding">
              <div class="col-8">
              </div>
              <div class="col-2">
                Grand Total
              </div>
              <div class="col-2">
                {{(chargeDetails.amount/100) | currency}}
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
      <div class="ms-4 me-4">
        <div>
          <app-notice [content]="noticeContent" type="warning"></app-notice>
      
          <div class="d-flex mt-4">
            <div class="flex-fill">
              <p class="mb-1" style="font-size: 0.875rem;">
                If you have any questions, please contact us at 
                <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> or call us at 
                <a href="tel:+13174830027">+1 317-483-0027</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Adjusted footer with reduced padding and split lines -->
      <div class="mt-3 small ms-4 me-4" style="font-size: 0.75rem; padding-top: 0.5rem;">
        <div>
          This receipt was generated for {{communityDetails.name}}, by Event Odyssey Inc. and will appear on your card statement as <em>{{chargeDetails.calculated_statement_descriptor}}</em>.
        </div>
        <div class="mt-2">
          Apple Wallet® is a registered trademark of Apple Inc. Google Wallet® is a registered trademark of Google LLC.
        </div>
      </div>
      
      
      
    </div>
  </ng-container>
  <ng-template #noDetails>
    <ng-container *ngIf="loading; else error">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #error>
      <div class="text-center">
        Receipt Generation Failed!
        <br>
        Pleace contact support <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a> for assistance.
      </div>
    </ng-template>
  </ng-template>