import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { CreateEventComponent } from '../../modals/create-event/create-event.component';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { EditEventComponent } from '../../modals/edit-event/edit-event.component';
import { TicketScannerComponent } from '../../ticket-scanner/ticket-scanner.component';
import { PurchaseSeatsComponent } from '../../modals/purchase-seats/purchase-seats.component';
import { BackendService } from 'src/app/services/backend.service';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';
import { UserService } from 'src/app/services/user.service';
import { LoginComponent } from '../../shared/login/login.component';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';
import { Subscription } from 'rxjs';
import { RedeemSeatsComponent } from '../../modals/redeem-seats/redeem-seats.component';
import { GuestPurchaseSeatsComponent } from '../../modals/guest-purchase-seats/guest-purchase-seats.component';
import { SellSeatsPosComponent } from '../../modals/sell-seats-pos/sell-seats-pos.component';

@Component({
  selector: 'app-community-events-home',
  templateUrl: './events-home.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./events-home.component.scss']
})
export class CommunityEventsHomeComponent implements OnInit {
  globalAdmin: boolean = false
  loggedIn: boolean = false
  loginModalRef: MdbModalRef<LoginComponent> | null = null;
  currentDate: Date = new Date()
  ticketScannerModalRef: MdbModalRef<TicketScannerComponent> | null = null;
  currentView: string = 'eventList'
  purchaseType: string = 'purchase'
  postDetails: any
  displaySelectDropdown: boolean = false
  venueDetails: any
  modalRef: MdbModalRef<CreateEventComponent> | null = null;
  seatSellPOSModalRef: MdbModalRef<SellSeatsPosComponent> | null = null;
  seatRedeemModalRef: MdbModalRef<RedeemSeatsComponent> | null = null;
  seatCheckoutModalRef: MdbModalRef<PurchaseSeatsComponent> | null = null;
  guestSeatCheckoutModalRef: MdbModalRef<GuestPurchaseSeatsComponent> | null = null;
  editEventModalRef: MdbModalRef<EditEventComponent> | null = null;
  seatmapBackgroundImg: string = ''
  @Input() communityDetails: Community
  @Input() id
  @Input() initialLoad
  seriesEvents = []
  selectedSeats = []
  seatData = []
  events: any[] = []
  loading: boolean = true
  loadingEvent: boolean = false
  isLoadingSeatData: boolean = true
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 24 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  displayMobileUi: boolean = false
  purchaseLimit: number = 10
  limit: number = 5
  error
  errorEvent
  orderDetails
  private communitiesSubscription: Subscription;
  isUserCommunityMember: boolean = false
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectWidthChanges(window.innerWidth)
  }
  constructor(
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private backendService: BackendService,
    private userService: UserService,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private toastService: MdbNotificationService
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.detectWidthChanges(window.innerWidth)
    this.getEvents()
    this.userService.userChanged.subscribe((user: any) => {
      if (user.id) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }
    });
    this.communitiesSubscription = this.joinedCommunitiesService.joinedCommunitiesChanged.subscribe((joinedCommunities: any[]) => {
      const joinedCommunitiesLoading = joinedCommunities[0]?.loading || false;
      if (!joinedCommunitiesLoading) {
        const currentEventCommunityId = this.communityDetails.id;
        // Check if the user has joined the current event's community
        this.isUserCommunityMember = !!joinedCommunities.find(community => community.id === currentEventCommunityId);

      }
      this.joinedCommunitiesService.globalAdminChanged.subscribe((isGlobalAdmin: any) => {
        if(typeof isGlobalAdmin === 'boolean') {
          this.globalAdmin = isGlobalAdmin
        }
      });
    });

  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const eventId = params.id
        if (eventId) {
          this.getEvent(eventId)
          this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
        }
      })
  }

  detectWidthChanges(screenWidth: number) {
    this.displayMobileUi = (screenWidth <= 900)
  }

  refreshseatData() {
    console.log('refresh seatmap')
    this.isLoadingSeatData = true
    this.backendService.getCommunityEventSeatmap(this.communityDetails.id, this.postDetails.id).subscribe({
      next: async (res) => {
        res.seatmap.push({
          "type": "Stage",
          "x": -12.5,
          "y": 3
        })
        this.seatData = res.seatmap
        this.seatmapBackgroundImg = res.backgroundImg
        this.isLoadingSeatData = false
        const element = document.getElementById('top-seatmap');
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          const offsetPosition = elementPosition - 90;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
        if (res.selectedSeats) {
          res.selectedSeats.forEach((savedSeatReservation: any) => {
            this.selectSeat(savedSeatReservation)
          })
        }
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  getEvents() {
    this.loading = true
    this.backendService.getCommunityEvents(this.communityDetails.id, {
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    }).subscribe({
      next: async (res) => {
        this.events = res
        this.seriesEvents = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }


  getEvent(eventId) {
    this.currentView = 'eventView'
    this.loadingEvent = true
    this.backendService.getCommunityEvent(this.communityDetails.id, eventId).subscribe({
      next: async (res) => {
        console.log('fetching event details')
        console.log(res)
        this.venueDetails = res.venueDetails
        this.postDetails = res
        this.loadingEvent = false
        this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + eventId)
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }


  login() {
    this.loginModalRef = this.modalService.open(LoginComponent,
      {
        data: {
          description: 'You must be logged in to purchase tickets!'
        },
        ignoreBackdropClick: true,
      }
    )
  }

  viewSeatmap(purchaseType: string) {
    switch (purchaseType) {
      case 'redeem':
        this.purchaseLimit = this.postDetails.compAccess.entitlements || 0
        break;
      case 'purchase':
        this.purchaseLimit = 10
        break;
      case 'sell-register1':
        this.purchaseLimit = 100
        break;
      case 'sell-register2':
        this.purchaseLimit = 100
        break;
    }
    this.refreshseatData()
    this.selectedSeats = []
    this.currentView = 'seatMap'
    this.purchaseType = purchaseType
  }

  checkout() {
    if (this.purchaseType === 'redeem') {
      return this.redeem()
    }
    if (this.purchaseType === 'sell-register1') {
      return this.posSale('NHS-001')
    }
    if (this.purchaseType === 'sell-register2') {
      return this.posSale('NHS-002')
    }
    console.log('Check user state', this.loggedIn)
    if (this.loggedIn) {
      this.seatCheckoutModalRef = this.modalService.open(PurchaseSeatsComponent, {
        modalClass: 'modal-dialog-scrollable modal-xl',
        ignoreBackdropClick: true,
        data: {
          communityDetails: this.communityDetails,
          eventDetails: this.postDetails,
          selectedSeats: this.selectedSeats,
          addonsDetails: this.postDetails.addons
        }
      })
      this.seatCheckoutModalRef.onClose.subscribe((data: any) => {
        this.selectedSeats = []
        if (data) {
          switch (data.message) {
            case 'purchaseComplete':
              this.orderDetails = data.orderDetails
              break;
            case 'seatsUnavailable':
              this.errorToast('The seats you selected are no longer available.')
              this.refreshseatData()
              break;
          }
        } else {
          // User cancelled or closed modal - refresh seatmap
          this.refreshseatData()
        }
      });
    } else {
      console.log('not logged in - continue to guest checkout')


      this.guestSeatCheckoutModalRef = this.modalService.open(GuestPurchaseSeatsComponent, {
        modalClass: 'modal-dialog-scrollable modal-xl',
        ignoreBackdropClick: true,
        data: {
          communityDetails: this.communityDetails,
          eventDetails: this.postDetails,
          selectedSeats: this.selectedSeats,
          addonsDetails: this.postDetails.addons
        }
      })
      this.guestSeatCheckoutModalRef.onClose.subscribe((data: any) => {
        this.selectedSeats = []
        if (data) {
          switch (data.message) {
            case 'purchaseComplete':
              this.orderDetails = data.orderDetails
              break;
            case 'seatsUnavailable':
              this.errorToast('The seats you selected are no longer available.')
              this.refreshseatData()
              break;
          }
        } else {
          // User cancelled or closed modal - refresh seatmap
          this.refreshseatData()
        }
      });



    }

  }

  redeem() {
    console.log('lets redeem instead')
    this.seatRedeemModalRef = this.modalService.open(RedeemSeatsComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails,
        selectedSeats: this.selectedSeats
      }
    })
    this.seatRedeemModalRef.onClose.subscribe((data: any) => {
      this.selectedSeats = []
      if (data) {
        switch (data.message) {
          case 'purchaseComplete':
            this.orderDetails = data.orderDetails
            break;
          case 'seatsUnavailable':
            this.errorToast('The seats you selected are no longer available.')
            this.refreshseatData()
            break;
        }
      } else {
        // User cancelled or closed modal - refresh seatmap
        this.refreshseatData()
      }
    });
  }

  posSale(registerId: string) {
    console.log('lets sell with POS instead')
    this.seatSellPOSModalRef = this.modalService.open(SellSeatsPosComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails,
        selectedSeats: this.selectedSeats,
        registerId: registerId
      }
    })
    this.seatSellPOSModalRef.onClose.subscribe((data: any) => {
      this.selectedSeats = []
      if (data) {
        switch (data.message) {
          case 'purchaseComplete':
            this.orderDetails = data.orderDetails
            break;
          case 'seatsUnavailable':
            this.errorToast('The seats you selected are no longer available.')
            this.refreshseatData()
            break;
        }
      } else {
        // User cancelled or closed modal - refresh seatmap
        this.refreshseatData()
      }
    });
  }


  calculateHeight(): string {
    const baseHeight = 230;
    const minimumHeight = 60;
    const heights = [
      minimumHeight,       // 0 items
      baseHeight / 4,     // 1 item
      baseHeight / 2,     // 2 items
      (3 * baseHeight) / 4,  // 3 items
      baseHeight         // 4 items and above
    ];
    const length = 1
    // const length = this.communities.length
    return Math.max(minimumHeight, heights[length] || baseHeight) + 'px';
  }

  ticketScanner() {
    this.ticketScannerModalRef = this.modalService.open(TicketScannerComponent, {
      modalClass: 'modal-dialog-scrollable modal-md navigatorModal',
      ignoreBackdropClick: true,
      containerClass: 'navigatorModal',
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.ticketScannerModalRef.onClose.subscribe((data: any) => {
      if (data === 'reset') {
        this.ticketScanner()
      }
    })
  }


  errorToast(message: string) {
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));  // Trigger a layout recalculation
    }, 50);
  }

  createEvent() {
    this.modalRef = this.modalService.open(CreateEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.getEvents()
      }
    })
  }

  selectSeat(seatData) {
    switch (seatData.type) {
      case 'selected':
        this.selectedSeats.push({
          section: seatData.section,
          id: seatData.id,
          priceArray: seatData.priceArray,
          row: seatData.row,
          seat: seatData.seat
        })
        break;
      case 'unselected':
        const index = this.selectedSeats.findIndex(seat => seat.id === seatData.id);
        if (index !== -1) {
          this.selectedSeats.splice(index, 1);
        }
        break;
    }
    this.cdRef.detectChanges()
  }

  editEvent() {
    this.editEventModalRef = this.modalService.open(EditEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails
      }
    })
    this.editEventModalRef.onClose.subscribe((data: any) => {
      if (data === 'created') {
        this.getEvent(this.postDetails.id)
      }
    })
  }


  viewDetails(item: any) {
    this.errorEvent = null
    this.loadingEvent = false
    this.currentView = 'eventView'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events?id=' + item.id)
    this.getEvent(item.id)
  }


  returnToList() {
    this.currentView = 'eventList'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/events')
  }

  enableTicketPurchase(salesStart: string | null): boolean {
    if (!salesStart) {
      return false; // No salesStart means tickets can be bought
    }
    if (this.isUserCommunityMember) {
      return false; // Allow community members to purchase tickets regardless of salesStart
    } else {
      return new Date(salesStart) >= this.currentDate;
    }
  }

}
