<div class="dangerFill">
  <div class=" mt-5 mb-4">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="row">
        <div #iconWrapper>
          <i class="fas fas fa-exclamation-circle fa-2x bg-danger bg-gradient text-light"></i>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 mt-3">
      <div class="row mb-3">
        <ng-container *ngIf="error; else noError">
          <h5 class="display-5 mb-0 pb-0 fw-bold primaryText">Server Error</h5>
          <span class="small secondaryText">Contact Event Odyssey for assistance</span>
        </ng-container>
        <ng-template #noError>

          <h5 class="display-5 mb-0 pb-0 fw-bold primaryText">Invalid Scan</h5>
          <span class="small secondaryText">{{ content.statusMessage }}</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>