<div class="no-zoom mb-0 pb-0" style="height: 100vh;">
    <!-- <div class="p-3 text-center main-area bg-image" style="height: 90vh">
        <div class="">
            <div class="d-flex justify-content-center align-items-center h-100">
                hello there
            </div>
        </div>
    </div>
    <div style="margin-top: -10px; padding-top: -50px;">
        <app-logo class="ms-2 mb-9 pb-5 mb-5" [height]="50" style="z-index: 2000"></app-logo>
    </div> -->



    <header>
        <div class="p-5 text-center bg-gradient-animation bg-image" style="height: 92vh;">
          <div class="mask">
            <div class="d-flex justify-content-center align-items-center h-100">
              <!-- <div class="text-white">
                <h4 class="display-4 mb-3">Hello World!</h4>
              </div> -->
              <div class="card" style="width: 90%; margin-top: -5%;">
                <div class="card-body">
                  <h1>Tickets still available for all nights!</h1>
                  <div class="row">
                    <div class="col">
                      <ngx-qrcode-styling [config]="config" [data]="'https://eventodyssey.com/communities/6qhykwxjl3cyi1tMdMV6/events?id=FdS6ge6x5Y060jBxnikR'"></ngx-qrcode-styling>
                      <h6>- SCAN QR CODE -</h6>
                    </div>
                    <div class="col">
                      <br><br><br><br>
                      <h2>$15 - Premium</h2>
                      <h2>$12 - Preferred</h2>
                      <h2>$10 - Standard</h2>
                      <br>
                      <br>
                      <h1>Register Cash Only</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="ps-4 mt-0 pt-0 mb-0 pb-0" style="height: 8vh; bottom: 0px;">
        <app-logo [height]="50" style="z-index: 2000;"></app-logo>
        <div class="small">© 2024 Event Odyssey Inc.</div>
      </div>
</div>


