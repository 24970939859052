import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-pos-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainPOSViewComponent implements OnInit {
  @Input() communityDetails
  @Input() terminalId
  listItems: any[] = []
  constructor() { }

  ngOnInit(): void {
  }

}
