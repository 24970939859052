import { NgModule } from '@angular/core'
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { LandingComponent } from './pages/landing/landing.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { CommunitiesComponent } from './pages/communities/communities.component'
import { MyVideosComponent } from './pages/my-videos/my-videos.component'
import { VideoPlayerComponent } from './pages/video-player/video-player.component'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthGuard } from './guards/user.guard'
import { LoginComponent } from './components/shared/login/login.component'
import { NotAuthenticatedComponent } from './pages/not-authenticated/not-authenticated.component'
import { CommunityDetailComponent } from './pages/communities/detail/detail.component'
import { CommunityManageComponent } from './pages/communities/manage/manage.component'
import { PurchasesComponent } from './components/user/purchases/purchases.component'
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component'
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component'
import { SupportComponent } from './pages/support/support.component'
import { PublicCommunityProfileComponent } from './pages/support/public-community-profile/public-community-profile.component'
import { CurrencyPipe } from '@angular/common'
import { LearnMoreComponent } from './pages/learn-more/learn-more.component'
import { PricingComponent } from './pages/pricing/pricing.component'
import { GetStartedComponent } from './pages/get-started/get-started.component'
import { MyTicketsComponent } from './pages/my-tickets/my-tickets.component'
import { ViewTicketComponent } from './pages/view-ticket/view-ticket.component'
import { ReceiptPdfPageComponent } from './components/pdf/receipt-pdf-page/receipt-pdf-page.component'
import { PayoutPdfPageComponent } from './components/pdf/payout-pdf-page/payout-pdf-page.component'
import { LinkTVComponent } from './pages/link-tv/link-tv.component'
import { RedeemComponent } from './pages/redeem/redeem.component'
import { ViewLabelComponent } from './components/pdf/view-label/view-label.component'
import { NavigatorComponent } from './components/navigator/navigator.component'
import { GlobalAdminGuard } from './guards/global-admin.guard'
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component'
import { AdminComponent } from './pages/admin/admin.component'
import { LinkLtiAccountComponent } from './pages/link-lti-account/link-lti-account.component'
import { MyStudentsComponent } from './components/user/my-students/my-students.component'
import { MyStudentViewComponent } from './components/user/my-students/student-view/student-view.component'
import { ReceiptPageComponent } from './components/pdf/receipt-page/receipt-page.component'
import { TicketPageComponent } from './components/pdf/ticket-page/ticket-page.component'
import { CustomerViewComponent } from './components/box-office/customer-view/customer-view.component'
import { NotFoundRedirectGuard } from './guards/not-found-redirect.guard'
import { EventManifestPageComponent } from './components/pdf/event-manifest-page/event-manifest-page.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: { animation: 'isLeft' }
  },
  { path: 'home', component: LandingComponent, data: { animation: 'isLeft' }  },
  { path: 'communities', component: CommunitiesComponent, data: { animation: 'isLeft' }  },
  { path: 'communities/:id', component: CommunityDetailComponent, data: { animation: 'isLeft' }},
  { path: 'communities/:id/:section', component: CommunityDetailComponent, data: { animation: 'isLeft' }},
  { path: 'communities/:id/manage', component: CommunityManageComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'communities/:id/manage/:section', component: CommunityManageComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'communities/:id/manage/:section/:settingsSection', component: CommunityManageComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'purchases', component: PurchasesComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'my-students', component: MyStudentsComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'my-students/:id', component: MyStudentViewComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'tickets', component: MyTicketsComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'tickets/:communityId/:eventId', component: ViewTicketComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'videos', component: MyVideosComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]},
  { path: 'videos/watch', component: VideoPlayerComponent, data: { animation: 'isRight' }, canActivate: [AuthGuard]},
  { path: 'learn-more', component: LearnMoreComponent, data: { animation: 'isLeft' }  },
  { path: 'get-started', component: GetStartedComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]  },
  { path: 'navigator', component: NavigatorComponent, data: { animation: 'isLeft' }  },
  { path: 'pricing', component: PricingComponent, data: { animation: 'isLeft' }  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { animation: 'isLeft' }  },
  { path: 'link-lti', component: LinkLtiAccountComponent, data: { animation: 'isLeft' } },
  { path: 'tv-link', component: LinkTVComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]  },
  { path: 'link-tv', component: LinkTVComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]  },
  { path: 'link-device', component: LinkTVComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]  },
  { path: 'test-link-tv', component: LinkTVComponent, data: { animation: 'isLeft' } },
  { path: 'redeem', component: RedeemComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard]  },
  { path: 'admin', component: AdminComponent, data: { animation: 'isLeft' }, canActivate: [AuthGuard, GlobalAdminGuard]  },
  { path: 'terms-of-service', component: TermsOfServiceComponent, data: { animation: 'isLeft' }  },
  { path: 'support', component: SupportComponent, data: { animation: 'isLeft' }  },
  { path: 'support/profile', component: PublicCommunityProfileComponent, data: { animation: 'isLeft' }  },
  { path: 'viewReceipt', component: ReceiptPdfPageComponent, data: { animation: 'isLeft' }  },
  { path: 'viewLabel', component: ViewLabelComponent, data: { animation: 'isLeft' }  },
  { path: 'viewPayout', component: PayoutPdfPageComponent, data: { animation: 'isLeft' }  },
  { path: 'generate-receipt', component: ReceiptPageComponent, data: { animation: 'isLeft' }  },
  { path: 'generate-event-manifest', component: EventManifestPageComponent, data: { animation: 'isLeft' }  },
  { path: 'generate-ticket-pdf', component: TicketPageComponent, data: { animation: 'isLeft' }  },
  { path: 'kiosk/customer-view', component: CustomerViewComponent, data: { animation: 'isLeft' }  },
  { path: 'not-found', component: NotFoundComponent, data: { animation: 'isLeft' }  },
  { path: 'not-authenticated', component: NotAuthenticatedComponent, data: { animation: 'isLeft' }  },
  { path: 'access-denied', component: AccessDeniedComponent, data: { animation: 'isLeft' }  },
  {
    path: '**',
    canActivate: [NotFoundRedirectGuard],
    component: NotFoundComponent
  }

]
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'}), BrowserModule, BrowserAnimationsModule],
  providers: [CurrencyPipe],
  exports: [RouterModule]
})
export class AppRoutingModule {}
