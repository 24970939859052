import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Community } from 'src/app/interfaces/community';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AddCardReaderComponent } from '../add-card-reader/add-card-reader.component';
import { MdbTableDirective, MdbTablePaginationComponent } from 'mdb-angular-ui-kit/table';
import { ConfirmActionComponent } from 'src/app/components/modals/confirm-action/confirm-action.component';
import { PointOfSaleComponent } from '../point-of-sale/point-of-sale.component';

@Component({
  selector: 'app-list-card-readers',
  templateUrl: './list-card-readers.component.html',
  styleUrls: ['./list-card-readers.component.scss']
})
export class ListCardReadersComponent implements OnInit {
  @ViewChild('table') cardReadersTable: MdbTableDirective<any>;
  @ViewChild('pagination') pagination: MdbTablePaginationComponent;
  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent>;
  modalRef: MdbModalRef<AddCardReaderComponent> | null = null;
  posModalRef: MdbModalRef<PointOfSaleComponent> | null = null;
  @Input() communityDetails: Community
  searchText: string
  loading: boolean = true
  cardReaders: any[] = []
  error
  constructor(
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user) => {
      this.fetchCardReaders()
    })
  }

  fetchCardReaders() {
    this.loading = true
    const callable = this.functions.httpsCallable('getCardReaders');
    const obs = callable({
      communityId: this.communityDetails.id
    });
    obs.subscribe({
      next: (res) => {
        this.cardReaders = res.data
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
      },
    })
  }

  createCardReader() {
    this.modalRef = this.modalService.open(AddCardReaderComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((message: any) => {
      if (message === 'created') {
        this.fetchCardReaders()
      }
    });
  }

  launchPOS(terminalId: string) {
    this.posModalRef = this.modalService.open(PointOfSaleComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        terminalId: terminalId
      }
    })
    this.posModalRef.onClose.subscribe((message: any) => {
      if (message === 'created') {

      }
    });
  }

  search(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.cardReadersTable.search(searchTerm);
    this.searchText = searchTerm
  }

  filterTable(data: any, searchTerm: string): boolean {
    return Object.keys(data).some((key: any) => {
      switch (key) {
        case 'device_type':
          let deviceType = data[key]
          return deviceType.toLowerCase().includes(searchTerm.toLowerCase());
        case 'ip_address':
          let ipAddress = data[key]
          return ipAddress.toLowerCase().includes(searchTerm.toLowerCase());
        case 'label':
          let label = data[key]
          return label.toLowerCase().includes(searchTerm.toLowerCase());
        case 'serial_number':
          let serial = data[key]
          return serial.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  }

  deleteReader(item) {
    let content = `<div class="text-center">Are you sure you want to delete <b>${item.label}</b>?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Delete Card Reader',
        content: content,
        confirmButton: 'Delete',
        confirmButtonPlural: 'Deleting',
        type: 'removeCardReader',
        miscData: {
          communityId: this.communityDetails.id,
          id: item.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'deleted') {
        this.fetchCardReaders()
      }
    });
  }

}
