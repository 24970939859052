<div class="modal-body shadow-4 ms-0 me-0 ps-0 pe-0" style="background-color: var(--scanner-darker); min-height: 500px">
  <ng-container *ngIf="error; else noError">
    <div class="row">
      <app-error-scan [error]="error"></app-error-scan>
      <div class="d-flex justify-content-between align-items-center h-100 p-2">
        <div (click)="dismissActionRequired()"  class="flex-grow-1 me-2">
          <button class="bg-danger bg-gradient text-light" style="width: 100%; padding: 10px; border: none; border-radius: 5px;">
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noError>
    <ng-container *ngIf="honeywellScanner; else useCameraScanner">
      <ng-container *ngIf="(scanContent)&&!(loadingTicketStatus); else noScanContent">
        <div>
          <app-ticket-progress [dontDismiss]="dontDismissed" [progressWidth]="progressWidth"></app-ticket-progress>
          <div class="text-center mp-2" *ngIf="!dontDismissed">
            <button class="btn btn-link" (click)="dontDismiss()">
              Don't Dismiss
            </button>
          </div>
          <div style="height: 40px; padding-top: 7px;" class="text-center" *ngIf="dontDismissed">
            <span *ngIf="(userActionRequired)" class="small text-muted">Action required below to continue scanning</span>
            <span *ngIf="!(userActionRequired)" class="small text-muted">Scan another ticket to continue</span>
          </div>
        </div>
        <div [ngSwitch]="scanContent.status">
          <div *ngSwitchCase="'valid'">
            <app-success-scan [content]="scanContent"></app-success-scan>
            <app-ticket-actions [content]="scanContent" (outputDismiss)="dismissActionRequired()" (outputForceValidate)="forceValidateTicket()"></app-ticket-actions>
            <app-ticket-details [content]="scanContent" ></app-ticket-details>
          </div>
          <div *ngSwitchCase="'warning'">
            <app-warning-scan [content]="scanContent"></app-warning-scan>
            <app-ticket-actions [content]="scanContent" (outputDismiss)="dismissActionRequired()" (outputForceValidate)="forceValidateTicket()"></app-ticket-actions>
            <app-ticket-details [content]="scanContent" ></app-ticket-details>
          </div>
          <div *ngSwitchDefault>
            <app-error-scan [content]="scanContent"></app-error-scan>
            <div class="d-flex justify-content-between align-items-center h-100 p-2">
              <div (click)="dismissActionRequired()"  class="flex-grow-1 me-2">
                <button class="bg-danger bg-gradient text-light" style="width: 100%; padding: 10px; border: none; border-radius: 5px;">
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noScanContent>
        <ng-container *ngIf="loadingTicketStatus; else enableScanning">
          <app-loading *ngIf="loadingTicketStatus"></app-loading>
        </ng-container>
        <ng-template #enableScanning>
          <div class="text-center">
            <div class="mt-5"><i class="fas fa-qrcode fa-7x"></i></div>
            <h2 class="mt-4 pb-0 mb-0">Scan Ticket</h2>
            <p class="small text-muted mb-5">Press and hold scan button</p>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
    <ng-template #useCameraScanner>
      <div *ngIf="hasPermission === false">
        <p>Please enable camera permission.</p>
        <button (click)="resetModal()()" class="btn btn-dark btn-rounded">Retry</button>
      </div>
      <div *ngIf="isScannerStopped">
        <p *ngIf="!loadingTicketStatus">
          <app-success-scan [content]="scanContent"></app-success-scan>
          <app-error-scan [content]="scanContent"></app-error-scan>
        </p>
      </div>
      <zxing-scanner #scannerElem
        [ngClass]="{'scanner-stopped': isScannerStopped, 'scanner-running': !isScannerStopped}"
        (scanSuccess)="scanSuccessHandler($event)" (scanError)="scanErrorHandler($event)"
        (camerasFound)="camerasFoundHandler($event)"
        (permissionResponse)="permissionResponseHandler($event)"></zxing-scanner>
      <!-- <button *ngIf="!isScannerStopped && availableDevices?.length > 1" (click)="toggleCamera()" class="btn btn-dark btn-rounded">Toggle Camera</button> -->
      <br>
    </ng-template>
  </ng-template>
</div>