import { Component, OnInit, OnDestroy } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { TicketScannerComponent } from '../../ticket-scanner/ticket-scanner.component';
import { NavigatorSettingsComponent } from '../navigator-settings/navigator-settings.component';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { ToastComponent } from '../../shared/toast/toast.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';

@Component({
  selector: 'app-navigator-home',
  templateUrl: './navigator-home.component.html',
  animations: [fadeInEnterAnimation({ duration: 200 }),],
  styleUrls: ['./navigator-home.component.scss']
})
export class NavigatorHomeComponent implements OnInit, OnDestroy {
  joinedCommunitiesLoading: boolean = true
  joinedCommunities: any[] = []
  communityDetails
  eventDetails
  ticketScannerModalRef: MdbModalRef<TicketScannerComponent> | null = null;
  navigatorSettingsModalRef: MdbModalRef<NavigatorSettingsComponent> | null = null;
  toastOptionsSuccess = environment.toastOptions.success as MdbNotificationConfig<{}>;
  toastOptionsError = environment.toastOptions.navigatorerror as MdbNotificationConfig<{}>;
  apps = [
    { name: 'Validate', icon: 'fas fa-ticket', color: 'bg-primary' },
    { name: 'Ticket List', icon: 'fas fa-clipboard-list', color: 'bg-primary' },
    { name: 'Event Stats', icon: 'fas fa-chart-line', color: 'bg-primary' },
    { name: 'Settings', icon: 'fas fa-cogs', color: 'bg-dark' },
    { name: 'Log Out', icon: 'fas fa-sign-out-alt', color: 'bg-dark' }
  ];
  loggedIn: boolean = false;
  loading: boolean = false;
  setupDetails: any = {
    setupCode: '',
    secret: '',
  };
  deviceId = 'navigator';
  userProfile: any;
  error: string | null = null;
  private subscriptions: Subscription = new Subscription();
  private loginStatusSubscription: Subscription = new Subscription();
  currentView = 'home'
  constructor(
    private modalService: MdbModalService,
    private toastService: MdbNotificationService,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.getUserData();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  click(name: string) {
    switch (name) {
      case "Validate":
        this.setView('validate')
        // this.validateTickets();
        break;
      case "Ticket List":
        this.setView('ticket-list')
        break;
      case "Event Stats":
        this.setView('event-stats')
        break;
      case "Settings":
        this.launchNavigatorSettings();
        break;
      case "Log Out":
        this.afAuth.signOut().then(() => {
          this.loading = false;
          this.communityDetails = null
          this.eventDetails = null
          this.toastOptionsSuccess['position'] = 'bottom-center';
          this.toastOptionsSuccess.data['content'] = 'You\'ve been logged out.';
          this.toastOptionsSuccess.data['title'] = 'Logout Successful';
          this.toastService.open(ToastComponent, this.toastOptionsSuccess)
        })
        break;
      default:
        break;
    }
  }

  fetchSetupCode() {
    const callable = this.functions.httpsCallable('getTVAuthCode');
    const obs = callable({ id: this.deviceId });
    const sub = obs.subscribe({
      next: (res) => {
        this.setupDetails = res;
        this.fetchRendezvousLoginState(res.documentPath);
      },
      error: (err) => {
        console.error(err);
        this.error = err.message;
      },
    });
    this.subscriptions.add(sub);
  }

  fetchAccessToken() {
    this.loading = true;
    const callable = this.functions.httpsCallable('getTVAuthToken');
    const obs = callable({
      id: this.deviceId,
      setupCode: this.setupDetails.setupCode,
      secret: this.setupDetails.secret
    });
    const sub = obs.subscribe({
      next: (res) => {
        console.log(res);
        // Login
        this.afAuth.setPersistence('local').then(() => {
          this.afAuth.signInWithCustomToken(res.token)
            .then(
              res => {
                console.log(res);
              },
              err => {
                this.errorToast(err.message);
              });
        });
      },
      error: (err) => {
        console.error(err);
        this.error = err.message;
        this.loading = false;
      },
    });
    this.subscriptions.add(sub);
  }

  fetchRendezvousLoginState(documentPath: string) {
    const rendezvousLoginRef = this.db.doc(documentPath).valueChanges();
    this.loginStatusSubscription = rendezvousLoginRef.subscribe((res: any) => {
      console.log('fetch rendezvous login');
      console.log(res);
      if (res?.status === 'authenticated') {
        this.fetchAccessToken();
  
        // Unsubscribe from further updates after detecting authentication
        if (this.loginStatusSubscription) {
          this.loginStatusSubscription.unsubscribe();
        }
      }
    });
  }
  

  getUserData() {
    const sub = this.afAuth.authState.subscribe((user) => {
      if (user) {
        const name = user.displayName ? user.displayName.split(' ')[0] : 'User';
        this.userProfile = {
          id: user.uid,
          given_name: name,
          full_name: user.displayName,
          ...(user.email && { email: user.email }),
          ...(user.providerData.length > 0 && user.providerData[0].email && { email: user.providerData[0].email }),
          provider: user.providerData.length > 0 ? user.providerData[0].providerId : 'lti',
          profilePicture: user.photoURL
        };
        this.joinedCommunitiesService.fetchJoinedCommunities()
        this.joinedCommunitiesService.joinedCommunitiesChanged.subscribe((joinedCommunities: any[]) => {
          if (joinedCommunities[0]?.loading) {
            this.joinedCommunitiesLoading = true
          } else {
            this.joinedCommunitiesLoading = false
            this.joinedCommunities = joinedCommunities;
            if(!this.eventDetails) {
              this.setView('change-assignment')
            }
            this.loggedIn = true;
          }

        });
      } else {
        this.fetchSetupCode();
        this.loggedIn = false;
        this.setupDetails = {
          setupCode: '',
          secret: '',
        };
      }
    });
    this.subscriptions.add(sub);
  }

  setAssignment($event) {
    this.communityDetails = $event.communityDetails
    this.eventDetails = $event.eventDetails
    console.log(this.eventDetails)
    this.setView('home')
  }

  validateTickets() {
    this.ticketScannerModalRef = this.modalService.open(TicketScannerComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen',
      ignoreBackdropClick: true,
      containerClass: 'right'
    });
    const sub = this.ticketScannerModalRef.onClose.subscribe((data: any) => {
      if (data === 'reset') {
        this.validateTickets();
      }
    });
    this.subscriptions.add(sub);
  }

  launchNavigatorSettings() {
    this.navigatorSettingsModalRef = this.modalService.open(NavigatorSettingsComponent, {
      modalClass: 'modal-dialog-scrollable modal-fullscreen',
      ignoreBackdropClick: true,
      containerClass: 'right'
    });
  }

  launchStats() { }

  errorToast(message: string) {
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError);
  }

  successToast(message: string) {
    this.toastOptionsSuccess.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsSuccess);
  }

  launchSalesMode() {
    this.setReaderInputs();
  }

  setReaderInputs() {
    console.log('set reader inputs');
    const callable = this.functions.httpsCallable('setTerminalInputs');
    const obs = callable({
      cardReaderId: 'test',
      stripeId: 'test'
    });
    const sub = obs.subscribe({
      next: (res) => {
        console.log(res);
        this.successToast(JSON.stringify(res));
      },
      error: (err) => {
        console.error(err);
        this.errorToast(JSON.stringify(err));
      },
    });
    this.subscriptions.add(sub);
  }

  setView(view: string) {
    this.currentView = view
  }
}
