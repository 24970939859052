import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-event-manifest-page',
  templateUrl: './event-manifest-page.component.html',
  styleUrl: './event-manifest-page.component.scss'
})
export class EventManifestPageComponent {
  customerDetails: any
  chargeDetails: any
  lineItems: any
  communityDetails: any
  loading: boolean = true
  public noticeContent: string = ''
  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService,
    private titleService: Title,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.fetchDetails()
  }


  fetchDetails() {
    console.log('lets fetch details')
    this.route.queryParams.subscribe(params => {
      let eventId = params['eventId'];
      let accessCode = params['accessCode'];
      console.log(eventId, accessCode)
      if(eventId && accessCode) { this.fetchPaymentDetails(eventId, accessCode) }
    })
  }
  
  fetchPaymentDetails(receiptId: string, accessCode: string) {
    console.log(receiptId)
    console.log(accessCode)

    this.backendService.getPaymentDetailsWithAccessCode(receiptId, accessCode).subscribe({
      next: async (res) => {
        console.log(res)
        if(res.error) {
          this.loading = false
          this.chargeDetails = null
        } else {
          this.communityDetails = res.communityDetails
          this.chargeDetails = res.charge
          this.customerDetails = res.customerDetails
          this.noticeContent =  `Your event tickets have been sent to ${this.customerDetails.email}. You can also access them at <a href="https://eventodyssey.com/tickets" target="_blank">https://eventodyssey.com/tickets</a> and add them to your Apple Wallet® or Google Wallet® for easy access.`;
          this.lineItems = res.lineItems
          this.titleService.setTitle('Receipt from ' + this.communityDetails.name)
          this.loading = false
        }
      },
      error: (err) => {
        this.loading = false
        this.chargeDetails = null
        console.log(err)
        console.warn(err)
        return
      },
    });

  }

}
