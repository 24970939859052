<ng-container [ngSwitch]="cardDetails.type">
  <ng-container *ngSwitchCase="'card'">
    <div *ngIf="cardDetails.card">
      <span *ngIf="cardDetails.card.wallet">
        <img *ngIf="cardDetails.card.wallet.type.includes('google_pay')" class="cardIcon" [src]="'./assets/card-icons/googlepay.svg'">
        <img *ngIf="cardDetails.card.wallet.type.includes('apple_pay')" class="cardIcon" [src]="'./assets/card-icons/applepay.svg'">
      </span>
      <img class="cardIcon" [src]="'./assets/card-icons/'+cardDetails.card.brand+'.svg'">
    &nbsp;●●●●&nbsp;&nbsp;{{cardDetails.card.last4}}
    </div>
    <div *ngIf="cardDetails.card_present">
      <img class="cardIcon" [src]="'./assets/card-icons/'+cardDetails.card_present.brand+'.svg'">
    &nbsp;●●●●&nbsp;&nbsp;{{cardDetails.card_present.last4}}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'cash'">
    <img class="cardIcon" [src]="'./assets/card-icons/cash.svg'">&nbsp;&nbsp;Cash
  </ng-container>
  <ng-container *ngSwitchDefault>
    Unknown Payment Method
  </ng-container>
</ng-container>
<br>

